<template>
    <div>
        <Dialog 
            v-model="showFeatureSelector" 
            :title="$t('FeatureList.customize')"
            width="40%"
            :hidePadding="true"
            :infoText="$t('FeatureSelector.info')"
        >
            <FeatureSelector />
        </Dialog>
        <Dialog
            v-model="showMissingProperties"
            width="60%"
            :title="$t('FeatureList.missing_properties.title')"
        >
            <span>{{ $t('FeatureList.missing_properties.text') }}</span>
            <JSONTreeview 
                :data="missingProperties"
                :translateData="true"
            />
        </Dialog>
        <v-skeleton-loader
            v-if="loading"
            type=  "list-item, list-item-avatar, 
                    list-item, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, 
                    list-item, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, 
                    list-item, list-item-avatar, list-item-avatar"
            elevation="2"
        />
        <v-list
            v-else
            :elevation="isMobileSize() ? 0 : 2"
            :style="`max-height: 100vh - 16px`"
            class="overflow-y-auto py-0"
        >
            <v-list-item 
                v-if="!isMobileSize()"
                class="my-2"
            >
                <HardwareSelector />
            </v-list-item>
            <v-list-item 
                v-if="!isMobileSize()"
                class="my-2"
            >
                <VersionSelector/>
            </v-list-item>
            
            <div v-if="isLoggedIn">
                <v-list-item @click="showFeatureSelector = true">
                    <v-list-item-icon>
                        <v-icon>mdi-playlist-edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="text-wrap" style="word-break: break-word">{{ $t("FeatureList.customize") }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider/>
            </div>

            <div v-if="$route.name != 'store-editor'">
                <v-list-item 
                    @click="showTemplateFeaturesEditor = !showTemplateFeaturesEditor"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-file-document-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="text-wrap" style="word-break: break-word">Configuration template</v-list-item-title>
                    </v-list-item-content> 
                </v-list-item>
                <v-divider/>
            </div>

            <v-expansion-panels 
                v-model="expand"
                flat 
            >
                <v-expansion-panel>
                    <v-expansion-panel-header v-if="template">All features</v-expansion-panel-header>
                    <v-expansion-panel-content class="px-0">
                        <v-list-item-group
                            color="primary"
                            class="px-0"
                        ><!-- v-if="!template || showAllFeatures" -->
                            <div v-for="category, categoryName in categories" :key="categoryName">
                                <v-subheader 
                                    v-if="Object.keys(featuresInCategory(categoryName)).length > 0"
                                    class='text-wrap' 
                                    style="word-break: break-word"
                                >
                                    {{ translate(category.title) }}
                                </v-subheader>
                                <div v-for="feature, featureName in featuresInCategory(categoryName)" :key="featureName">
                                    <!-- if the feature is not supported yet, generate a disabled item in the list -->
                                    <v-list-item 
                                        v-if="!featureMinVersionRequirementsMet(feature.min_version)" 
                                        disabled
                                    >
                                        <v-list-item-icon>
                                            <v-icon disabled>
                                                {{ feature.icon }}
                                            </v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ translate(feature.title) }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle class='text-wrap' style="word-break: break-word">
                                                {{ $t('FeatureList.version_too_low')}}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <!-- if the feature is not supported anymore, generate a disabled item in the list -->
                                    <v-list-item 
                                        v-else-if="!featureMaxVersionRequirementsMet(feature.max_version)" 
                                        disabled
                                    >
                                        <v-list-item-icon>
                                            <v-icon disabled>
                                                {{ feature.icon }}
                                            </v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ translate(feature.title) }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle class='text-wrap' style="word-break: break-word">
                                                {{ $t('FeatureList.version_too_high') }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <!-- if the feature is in the spec but not currently in the template-->
                                    <v-list-item 
                                        v-else-if="featureIsUndefined(featureName)"
                                        disabled
                                    >
                                        <v-list-item-icon>
                                            <v-icon disabled>
                                                {{ feature.icon }}
                                            </v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ translate(feature.title) }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle class='text-wrap' style="word-break: break-word">
                                                {{ $t('FeatureList.feature_is_undefined') }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <!-- if the feature has no property groups, generate an item in the list -->
                                    <v-list-item 
                                        v-else-if="feature.byte_groups"
                                        @click="selected = {featureName}"
                                    >
                                        <v-list-item-icon>
                                            <v-badge
                                                icon="mdi-pencil"
                                                :value="featureIsDirty(featureName)"
                                                color='warning'
                                            >
                                                <v-icon :color="featureIsValid(featureName) ? null : 'red'">
                                                    {{ feature.icon }}
                                                </v-icon>
                                            </v-badge>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title :class="featureIsValid(featureName) ? 'text-wrap' : 'text-wrap font-weight-bold red--text'" style="word-break: break-word">
                                                {{ translate(feature.title) }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <!-- if the feature does have property groups, generate an item group in the list -->
                                    <v-list-group v-else-if="feature.property_groups">
                                        <template v-slot:prependIcon>
                                            <v-badge
                                                icon="mdi-pencil"
                                                :value="featureIsDirty(featureName)"
                                                color='warning'
                                            >
                                                <v-icon :color="featureIsValid(featureName) ? null : 'red'">
                                                    {{ feature.icon }}
                                                </v-icon>
                                            </v-badge>
                                        </template>
                                        <template v-slot:activator>
                                            <v-list-item-content>
                                                <v-list-item-title :class="featureIsValid(featureName) ? 'text-wrap' : 'text-wrap font-weight-bold red--text'" style="word-break: break-word">
                                                    {{ translate(feature.title) }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        <v-list-item 
                                            v-for="propertyGroup, propertyGroupName in feature.property_groups" 
                                            :key="propertyGroupName"
                                            @click="selected = {featureName, propertyGroupName}"
                                        >
                                            <v-list-item-content>
                                                <v-badge
                                                    :value="propertyGroupIsDirty(featureName, propertyGroupName)"
                                                    color='warning'
                                                    dot
                                                    inline
                                                >    
                                                    <v-list-item-icon/> <!-- required for correct spacing -->
                                                    <v-list-item-title :class="propertyGroupIsValid(featureName, propertyGroupName) ? 'text-wrap' : 'text-wrap red--text'" style="word-break: break-word">
                                                        {{ translate(propertyGroup.title) }}
                                                    </v-list-item-title>
                                                </v-badge>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-group>
                                </div>
                            </div>
                        </v-list-item-group>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-list>
    </div>
</template>

<script>
import Dialog from '@/components/dialogs/Dialog.vue'
import FeatureSelector from '@/components/FeatureSelector.vue'
import JSONTreeview from '@/components/JSONTreeview.vue'
import VersionSelector from '@/components/VersionSelector.vue'
import HardwareSelector from '@/components/HardwareSelector.vue';

import stMinVersionMet from '@/utils/stMinVersionMet.js';
import stMaxVersionMet from '@/utils/stMaxVersionMet.js';

import { getProperty } from 'dot-prop';

export default {
    components: {
        Dialog,
        FeatureSelector,
        JSONTreeview,
        VersionSelector,
        HardwareSelector
    },
    data: () => ({
        showFeatureSelector: false,
        showMissingProperties: false,
        expand: 0
    }),
    computed: {
        loading() {
            return this.$store.getters.loadingEditor
        },
        categories() {
            return this.$store.getters.categories
        },
        features() {
            return this.$store.getters.features
        },
        selected: {
            get() {
                return this.$store.getters.selected
            },
            set(newValue) {
                this.$store.dispatch("setSelected", newValue)
            }
        },
        versions() {
            return this.$store.getters.versions
        },
        hardware() {
            return this.$store.getters.hardware
        },  
        difficulty() {
            return this.$store.getters.difficulty
        },
        featureNamesInDesired() {
            return this.$store.getters.featureNamesInDesired
        },
        missingProperties() {
            return this.$store.getters.missingProperties
        },
        isLoggedIn() {
            return this.$store.getters.isLoggedIn
        },
        template() {
            return this.$store.getters.template
        },
        showTemplateFeaturesEditor: {
            get() {
                return this.$store.getters.showTemplateFeaturesEditor
            },
            set(show) {
                this.$store.dispatch('showTemplateFeaturesEditor', show)
            }
        }
    },
    methods: {
        featuresInCategory(category) {
            let featuresInCategory = {}
            Object.keys(this.features).forEach(featureName => {
                let feature = this.features[featureName]
                if(feature.category != category) return
                if(this.hardware && !feature.hardware.includes(this.hardware)) return
                featuresInCategory[featureName] = feature
            })
            return featuresInCategory
        },
        featureIsDirty(featureName) {
            return this.$store.getters.dirty(featureName)
        },
        featureIsValid(featureName) {
            return this.$store.getters.valid(featureName)
        },
        featureIsUndefined(featureName) {
            return this.featureNamesInDesired.indexOf(featureName) == -1
        },
        propertyGroupIsDirty(featureName, propertyGroupName) {
            return this.$store.getters.dirty(featureName, propertyGroupName)
        },
        propertyGroupIsValid(featureName, propertyGroupName) {
            return this.$store.getters.valid(featureName, propertyGroupName)
        },
        featureMinVersionRequirementsMet(featureMinVersions) {
            let stMin = featureMinVersions.st
            return stMinVersionMet(this.versions.st, stMin);
        },
        featureMaxVersionRequirementsMet(featureMaxVersions) {
            let stMax = getProperty(featureMaxVersions, 'st')
            return stMaxVersionMet(this.versions.st, stMax);
        }
    },
    watch: {
        missingProperties(properties) {
            if(properties && Object.keys(properties).length > 0) {
                this.showMissingProperties = true
            }
        },
        template(template) {
            if(template) { 
                this.expand = null
            }
            else { 
                this.expand = 0
            }
        },
        selected(val) {
            if(val) {
                this.showTemplateFeaturesEditor = false
            }
        },
        showTemplateFeaturesEditor(val) {
            if(val) {
                this.selected = null
            }
        }
    }
}
</script>

<style scoped>
.v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
    padding: 0 !important;
}
</style>